import { gql } from '@apollo/client';

export const GET_GROUPS_DATA_QUERY = gql`
  query GetAllCommercialGroups {
    groups {
      data {
        id
        name
        legalName
        docType
        docNumber
      }
    }
  }
`;
